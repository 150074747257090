import Script, { ScriptProps } from 'next/script'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { Values } from '@design-system/utils/helpers'
import { GTMValue, GTMEvent } from '@design-system/types/GTMTypes'

declare global {
    interface Window {
        dataLayer?: Record<string, any>[]
    }
}

/**
 * @see https://github.com/vercel/next.js/tree/canary/examples/with-google-tag-manager
 */
export const useGTM = (trackPageViews: (url: string) => void = defaultTrackPageViews) => {
    const router = useRouter()

    useEffect(() => {
        if (!router.isReady || typeof window === undefined) return

        router.events.on('routeChangeComplete', trackPageViews)

        return () => {
            router.events.off('routeChangeComplete', trackPageViews)
        }
    }, [trackPageViews, router])
}

export const defaultTrackPageViews = (url: string) => {
    if (!window.dataLayer || !window) return
    window.dataLayer.push({
        event: 'pageview',
        page: url,
    })
}

export const sendGTMEvent = (
    event: Values<GTMEvent>,
    value?: Values<GTMValue>,
    data: Record<string, unknown> | string = {}
): void => {
    if (!window.dataLayer || !window) return
    window.dataLayer.push({
        event,
        value,
        data,
    })
}

export interface GoogleTagManagerProps extends Omit<ScriptProps, 'id'> {
    gtmId: string
    containerId?: string
    trackPageViews?: (url: string) => void
}

/**
 * @description GoogleTagManagerScript is recommended to be placed in the _app.tsx file.
 */
export const GoogleTagManagerScript = ({ gtmId, containerId = 'gtag-base', trackPageViews }: GoogleTagManagerProps) => {
    useGTM(trackPageViews)

    return (
        <Script
            async
            id={containerId}
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer', '${gtmId}');
          `,
            }}
        />
    )
}

/**
 * @description GoogleTagManagerNoScript is recommended to be placed in the _document.tsx file, right under the opening body tag.
 */
export const GoogleTagManagerNoScript = ({ gtmId }: GoogleTagManagerProps) => (
    <noscript>
        <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
        />
    </noscript>
)
