import { initializeApp, getApp, getApps } from 'firebase/app'
import {
    getAuth,
    signInWithCustomToken,
    connectAuthEmulator,
    setPersistence,
    browserLocalPersistence,
} from '@firebase/auth'
import { unstable_noStore as noStore } from 'next/cache'

export const cookieName = process.env.NEXT_PUBLIC_AUTH_FIREBASE_COOKIE_NAME as string
export const cookieAuthUserUUID = process.env.NEXT_PUBLIC_AUTH_USER_UUID as string
export const maxAgeCookie = 60 * 60 * 24 * 70 // 10 weeks

export const getFirebaseApp = () => {
    if (getApps().length) {
        return getApp()
    }

    // The firebae emulator is started in a docker container
    // check out the README.md for more information
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'local') {
        const firebaseApp = initializeApp({
            apiKey: 'demo-api-key',
            projectId: 'demo-project',
        })
        connectAuthEmulator(getAuth(), 'http://127.0.0.1:9199')
        return firebaseApp
    } else {
        noStore()
        return initializeApp({
            apiKey: (process.env.NEXT_PUBLIC_FIREBASE_API_KEY || 'PUT_IN_A_DUMMY_API_KEY') as string,
            authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN as string,
            projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID as string,
            storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET as string,
            messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID as string,
            appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID as string,
            measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID as string,
        })
    }
}

export const auth = getAuth(getFirebaseApp())

export async function validateAndSignInWithCustomToken<T>(customIdToken: string, data: T, authUserUUID?: string) {
    try {
        await setPersistence(auth, browserLocalPersistence)
        await signOut()
        const userCredential = await signInWithCustomToken(auth, customIdToken)
        const idToken = await userCredential.user.getIdToken()

        if (!idToken) {
            throw new Error('No idToken found')
        }
        localStorage.setItem(cookieName, idToken)
        // setCookie(cookieName, idToken, {
        //     maxAge: maxAgeCookie,
        // })

        localStorage.setItem(cookieAuthUserUUID, authUserUUID || '')
        // setCookie(cookieAuthUserUUID, authUserUUID || '', {
        //     maxAge: maxAgeCookie,
        // })
    } catch {
        localStorage.removeItem(cookieName)
        localStorage.removeItem(cookieAuthUserUUID)
    }
    return data
}

export async function signOut() {
    try {
        await auth.signOut()
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
    }
    localStorage.removeItem(cookieName)
    localStorage.removeItem(cookieAuthUserUUID)
}

export const isUserLoggedInWithUserUUID = (userUUID: string) => {
    return !!(localStorage.getItem(cookieAuthUserUUID) === userUUID && cookieName)
}
