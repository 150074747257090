import { ChangeEvent, forwardRef, InputHTMLAttributes, KeyboardEvent, useCallback, useMemo } from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { noOp, omit } from '@design-system/utils/helpers'
import { EnterTheme, useTheme } from '@design-system/styles/theme'

type KeyboardType = 'text' | 'number'

type Validate = (value: string) => Validation[]

export type Validation = {
    isValid: boolean
    onInvalid?: (
        setError: Exclude<TextInputProps['setError'], undefined>,
        currentValidation: Omit<Validation, 'onInvalid'> & { value: string },
        prevValidation?: Partial<Omit<Validation, 'onInvalid'> & { value: string }>
    ) => boolean | void
}

export interface TextInputProps {
    onValueChange: (value: string) => void
    onBlur?: () => void
    onFocus?: () => void
    value: string
    placeholder?: string
    type?: 'text' | 'password' | 'number' | 'tel' | 'email'
    inputStyles?: SerializedStyles
    error?: string | null
    setError?: (error: string | null) => void
    validate?: Validate
    clearError?: () => void
    isDisabled?: boolean
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void
    keyboardType?: KeyboardType
    styleType?: 'default' | 'inverted'
    dataCy?: string
    /**
     * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
     */
    autoComplete?: string
    autoFocus?: boolean
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
    (
        {
            onValueChange,
            onBlur,
            onFocus,
            onKeyDown,
            value,
            placeholder,
            clearError = noOp,
            type = 'text',
            inputStyles,
            error,
            setError,
            isDisabled,
            keyboardType,
            autoComplete,
            validate,
            autoFocus = false,
            styleType = 'default',
            dataCy,
        },
        ref
    ) => {
        const theme = useTheme()

        // --- CALLBACKS ---

        const handleChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                if (validate) {
                    const currentValidations = validate?.(event.target.value)
                    const currentError = currentValidations?.find((validation) => !validation.isValid)
                    const prevValidations = validate?.(value)
                    const prevError = prevValidations?.find((validation) => !validation.isValid)

                    if (!currentError) {
                        onValueChange(event.target.value)
                        if (error) clearError?.()

                        return
                    }

                    if (!setError) return

                    const invalidate = currentError.onInvalid?.(
                        setError,
                        {
                            ...omit(currentError, 'onInvalid'),
                            value: event.target.value,
                        },
                        {
                            ...(prevError ? omit(prevError, 'onInvalid') : {}),
                            value: value,
                        }
                    )

                    if (invalidate) return

                    onValueChange(event.target.value)

                    return
                }

                onValueChange(event.target.value)

                if (error) clearError?.()
            },
            [validate, onValueChange, error, clearError, value, setError]
        )

        const handleBlur = useCallback(() => onBlur?.(), [onBlur])

        const handleFocus = useCallback(() => {
            clearError()
            onFocus?.()
        }, [onFocus, clearError])

        // --- MEMOIZED ---

        const keyboardPattern: {
            inputMode: InputHTMLAttributes<HTMLInputElement>['inputMode']
            pattern: string | undefined
        } = useMemo(() => {
            switch (keyboardType) {
                case 'number':
                    return { pattern: '[0-9]*', inputMode: 'numeric' }

                case 'text':
                    return { pattern: undefined, inputMode: 'text' }

                default:
                    return type === 'number'
                        ? { pattern: '[0-9]*', inputMode: 'numeric' }
                        : { pattern: undefined, inputMode: 'text' }
            }
        }, [keyboardType, type])

        const { inputStyles: baseInputStyles } = useMemo(() => getBaseInputStyles(theme, styleType), [theme, styleType])

        // --- RENDER ---

        return (
            <input
                data-cy={dataCy}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                disabled={isDisabled}
                type={type}
                ref={ref}
                onChange={handleChange}
                onFocus={handleFocus}
                onKeyDown={onKeyDown}
                onBlur={handleBlur}
                value={value}
                placeholder={placeholder}
                css={css`
                    ${baseInputStyles};

                    ${inputStyles};
                `}
                {...keyboardPattern}
            />
        )
    }
)

export const INPUT_PADDING_PX = `16px 16px`
export const INPUT_MIN_HEIGHT = '57px'
export const INPUT_BORDER = 'none'

const getBaseInputStyles = (
    theme: EnterTheme,
    styleType: TextInputProps['styleType']
): {
    inputStyles: SerializedStyles
} => {
    const defaultInuputStyles = css`
        font-size: ${theme.fontSize.input || theme.fontSize.bodyDefault};
        font-weight: ${theme.fontWeight.input || theme.fontWeight.bodyDefault};
        font-family: ${theme.fontFamily.bodyDefault};
        padding: ${INPUT_PADDING_PX};
        min-height: ${INPUT_MIN_HEIGHT};
        border-radius: ${theme.borderRadius.input || theme.borderRadius.default};
        border: ${INPUT_BORDER};
        color: ${theme.colors.onInput};
        background: ${theme.colors.input};
        outline: 1px solid transparent;
        transition: all 0.4s ease;
        width: 100%;

        &:focus {
            outline: 1px solid transparent;
        }

        &::placeholder {
            color: ${theme.colors.text.secondary.onLight};
        }

        &:disabled {
            color: ${theme.palette.grey[2]};
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type='number'] {
            -moz-appearance: textfield;
        }
    `

    switch (styleType) {
        case 'inverted':
            return {
                inputStyles: css`
                    ${defaultInuputStyles};

                    background-color: ${theme.colors.background.light};
                `,
            }

        default:
            return {
                inputStyles: defaultInuputStyles,
            }
    }
}
