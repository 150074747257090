import { css, SerializedStyles } from '@emotion/react'

import { useTheme } from '@design-system/styles/theme'
import { GTMDataLayerProps } from '@design-system/types/GTMTypes'
import { Button, ButtonProps } from '@design-system/components/button/button'
import { PropsWithChildren } from 'react'

export interface CardProps extends GTMDataLayerProps {
    onCardClick?: () => void
    containerStyles?: SerializedStyles
    buttonStyles?: SerializedStyles | SerializedStyles[]
    buttonProps?: Omit<ButtonProps, 'children' | 'onClick' | 'buttonStyles' | 'childrenContainerStyles' | 'styleType'>
    dataTestid?: string
}

export const CARD_PADDING_PX: `${number}px` = '20px'

export const Card = ({
    onCardClick,
    children,
    containerStyles,
    buttonStyles,
    gtmValue,
    buttonProps,
    dataTestid,
}: PropsWithChildren<CardProps>) => {
    // --- STATE ---

    const theme = useTheme()

    // --- RENDER ---
    const styles = css`
        display: flex;
        flex-direction: column;
        width: 100%;
        background: ${theme.colors.background.light};
        border-radius: ${theme.borderRadius.card};
        padding: ${CARD_PADDING_PX};
        align-items: flex-start;
        text-align: left;

        ${onCardClick &&
        css`
            cursor: pointer;
        `}

        ${containerStyles}
    `

    if (onCardClick) {
        return (
            <Button
                onClick={onCardClick}
                styleType="inline-unstyled"
                buttonStyles={styles}
                childrenContainerStyles={css`
                    ${buttonStyles}
                `}
                gtmValue={gtmValue}
                {...buttonProps}
            >
                {}
                {children}
            </Button>
        )
    }

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                width: 100%;
                background-color: ${theme.colors.background.light};
                border-radius: ${theme.borderRadius.card};
                padding: ${CARD_PADDING_PX};

                ${onCardClick &&
                css`
                    cursor: pointer;
                `}

                ${containerStyles}
            `}
            data-testid={dataTestid}
        >
            {children}
        </div>
    )
}
