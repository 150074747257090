import { GetBuildings, OpportunityStage } from '@design-system/types/buildings/buildingsTypes'
import { getLatestOppourtnity, getIsAtOrPassedOpportunityStage } from '@design-system/utils/buildings/buildingsHelpers'

export const getCustomerType = (building: GetBuildings | undefined) => {
    const latestOpportunity = getLatestOppourtnity(building)

    if (!building) return UserType.VISITOR
    if (!latestOpportunity) return UserType.LEAD

    const userIsAtOrPassedBasicsStage = getIsAtOrPassedOpportunityStage(
        latestOpportunity?.currentStage,
        OpportunityStage.Basics
    )

    return userIsAtOrPassedBasicsStage ? UserType.CUSTOMER : UserType.LEAD
}

export enum UserType {
    VISITOR = 'visitor',
    LEAD = 'lead',
    CUSTOMER = 'customer',
}
