import { css } from '@emotion/react'

import Text from '@design-system/components/text/text'
import { useTheme } from '@design-system/styles/theme'
import { Button, ButtonProps, StylesType } from '@design-system/components/button/button'
import { EnterIllustration, IllustrationName } from '@design-system/enter-icons/enterIllustration'

import DefaultModal, { ModalProps } from './defaultModal'
import { EnterIcon, IconName } from '@design-system/enter-icons/enterIcon'
import React, { PropsWithChildren } from 'react'

type ModalHandlerProps = {
    content: string
    iconName?: IconName
    styleType?: ButtonProps['styleType']
    buttonStyles?: StylesType
    onClick?: () => void
}

export interface DialogModalProps extends ModalProps {
    illustration?: IllustrationName
    title: string | React.ReactNode
    primaryHandler?: ModalHandlerProps
    secondaryHandler?: ModalHandlerProps
}

export const DialogModal = ({
    illustration,
    title,
    primaryHandler,
    secondaryHandler,
    ...props
}: PropsWithChildren<DialogModalProps>) => {
    const theme = useTheme()
    if (!props.isOpen) return null
    return (
        <DefaultModal
            modalStyles={css`
                min-width: 320px;
                max-width: 360px;
                padding: ${theme.spacing(4)};
            `}
            {...props}
        >
            <section
                css={css`
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    gap: ${theme.spacing(3)};
                `}
            >
                {illustration && (
                    <div
                        css={css`
                            display: flex;
                            justify-content: center;
                        `}
                    >
                        <EnterIllustration name={illustration} width={96} height={96} />
                    </div>
                )}
                <header>
                    <Text variant="headline" as="h3">
                        {title}
                    </Text>
                </header>
                <article
                    css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    `}
                >
                    {props.children}
                </article>
                <footer
                    css={css`
                        display: flex;
                        flex-direction: column;
                        row-gap: ${theme.spacing(1)};
                    `}
                >
                    {primaryHandler && (
                        <Button
                            onClick={primaryHandler?.onClick}
                            styleType={primaryHandler.styleType || 'secondary'}
                            buttonStyles={css`
                                flex: 1;
                                ${primaryHandler.buttonStyles}
                            `}
                        >
                            {primaryHandler?.content}
                            {primaryHandler?.iconName && <EnterIcon name={primaryHandler.iconName} />}
                        </Button>
                    )}
                    {secondaryHandler && (
                        <Button
                            onClick={secondaryHandler?.onClick}
                            styleType={secondaryHandler.styleType || 'secondary-outlined'}
                            buttonStyles={css`
                                flex: 1;
                                ${secondaryHandler.buttonStyles}
                            `}
                        >
                            {secondaryHandler?.content}
                            {secondaryHandler?.iconName && <EnterIcon name={secondaryHandler.iconName} />}
                        </Button>
                    )}
                </footer>
            </section>
        </DefaultModal>
    )
}
