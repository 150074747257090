import React, { useId } from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTheme, IconScaleSize } from '../styles/theme'
import { motion, MotionProps } from 'framer-motion'
import { OPACITY_TRANSITION } from '../styles/helpers'

export enum IconName {
    Applicant = 'applicant',
    Owner = 'owner',
    Angaben = 'angaben',
    ZeroState = 'zero-state',
    BuildingDetails2 = 'building-details-2',
    Data = 'data',
    Arrow = 'arrow',
    ArrowLeft = 'arrow-left',
    ArrowNarrowDown = 'arrow-narrow-down',
    ArrowNarrowDownLeft = 'arrow-narrow-down-left',
    ArrowNarrowDownRight = 'arrow-narrow-down-right',
    ArrowNarrowLeft = 'arrow-narrow-left',
    ArrowNarrowRight = 'arrow-narrow-right',
    ArrowNarrowUp = 'arrow-narrow-up',
    ArrowNarrowUpLeft = 'arrow-narrow-up-left',
    ArrowNarrowUpRight = 'arrow-narrow-up-right',
    ArrowRight = 'arrow-right',
    ArrowUpRight = 'arrow-up-right',
    BasementWall = 'basement-wall',
    Bookmark = 'bookmark',
    Heating = 'heating',
    Cloud01 = 'cloud-01',
    Comment01 = 'comment-01',
    Door = 'door',
    Document = 'document',
    Edit01 = 'edit-01',
    Edit02 = 'edit-02',
    CurrencyEuroCircle = 'currency-euro-circle',
    Windows = 'windows',
    Fire = 'fire',
    FloorPlate01 = 'floor-plate-01',
    FloorPlate02 = 'floor-plate-02',
    HeatPump = 'heatpump',
    Walls = 'walls',
    UpperFloor = 'upper-floor',
    RoofWindow = 'roof-window',
    SolarPanels = 'solar-panels',
    Sunscreens = 'sunscreens',
    CheckCircleFilled = 'check-circle-filled',
    Trash04 = 'trash-04',
    SearchSm = 'search-sm',
    XMark = 'x-mark',
    MessageQuestionSquare = 'message-question-square',
    User01 = 'user-01',
    Plus = 'plus',
    PlusSquare = 'plus-square',
    DotsVertical = 'dots-vertical',
    Check = 'check',
    InfoCircle = 'info-circle',
    ChevronRight = 'chevron-right',
    ChevronLeft = 'chevron-left',
    ChevronUp = 'chevron-up',
    ChevronDown = 'chevron-down',
    Upload01 = 'upload-01',
    File05 = 'file-05',
    Eye = 'eye',
    Download01 = 'download-01',
    Folder = 'folder',
    Zap = 'zap',
    Pin = 'pin',
    Menu01 = 'menu-01',
    Roof = 'roof',
    SmartHome = 'smart-home',
    HeatingNiches = 'heating-niches',
    BuildingDetails = 'building-details',
    BuildingKeyData = 'building-key-data',
    BuildingExtension = 'building-extension',
    BuildingLocation = 'building-location',
    BasementCeiling = 'basement-ceiling',
    Basement = 'basement',
    FileX03 = 'file-x-03',
    FlipBackward = 'flip-backward',
    BasementFloor = 'basement-floor',
    Wall = 'wall',
    Wintergarten = 'wintergarten',
    HomeSmile = 'home-smile',
    HomeSad = 'home-sad',
    CoinsStacked02 = 'coins-stacked-02',
    GeneralHome = 'general-home',
    GeneralHome02 = 'general-home-02',
    BarChartSquare02 = 'bar-chart-square-02',
    Tool02 = 'tool-02',
    XCircle = 'x-circle',
    CoinsStacked01 = 'coins-stacked-01',
    LineChartUp03 = 'line-chart-up-03',
    ClipboardCheck = 'clipboard-check',
    Wallet03 = 'wallet-03',
    PhoneCall01 = 'phone-call-01',
    Calendar = 'calendar',
    ProfessionalSupport = 'professional-support',
    Finance = 'finance',
    Clock = 'clock',
    Subsidy = 'subsidy',
    Gift = 'gift',
    BatteryFull = 'battery-full',
    Status = 'status-up',
    MedalStar = 'medal-star',
    Moneys = 'moneys',
    XCircleInverted = 'x-circle-inverted',
    WalletMoney = 'wallet-money',
    EmptyWalletTime = 'empty-wallet-time',
    ShieldTick = 'shield-tick',
    Minus = 'minus',
    CircleFilled = 'circle-filled',
    PiggyBank = 'piggy-bank',
    Amortisation = 'amortisation',
    DiscountShape = 'discount-shape',
    DocumentText = 'document-text',
    FavoriteChart = 'favorite-chart',
    Group = 'group',
    HomeTrendUp = 'home-trend-up',
    Messages = 'messages',
    RulerPen = 'ruler-pen',
    Star = 'star',
    MathSignOCross01 = 'math-sign-o-cross-01',
    TrendUpwards01 = 'trend-upwards-01',
    PresentationChart01 = 'presentation-chart-01',
    TagIcon = 'tag-icon',
    Contact = 'contact',
    QuestionCircle = 'question-circle',
    RoundedChart = 'rounded-chart',
    RoundedTrendUp = 'rounded-trend-up',
    RoundedChartSquare = 'rounded-chart-square',
    TaskSquare = 'task-square',
}

export type EnterIconProps<T extends IconName = IconName> = {
    name: T
    styles?: SerializedStyles
    size?: `${IconScaleSize}`
    motionProps?: MotionProps
}

const getContainerStylesBySize = (size: number) => css`
    width: ${size}px;
    height: ${size}px;
    min-width: ${size}px;
    min-height: ${size}px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
    }
`

/**
 * @TODO In order to prepare a new icon for use in this component, you need to:
 * 1. Add the icon to the `packages/lib/enter-icons/svg` folder.
 * 2. Add a suffix to the file name if it is a filled icon. The default 'outline' icons has no any suffix in the name.
 * 3. Make sure the attributes (fill or stroke) in the svg tags have the color defining property.
 *    An attribute responsible for the color must have "currentColor" value, while another one must be set to "transparent".
 * 4. Add the icon name to the `IconName` enum.
 */
export const EnterIcon = ({ name, styles, size = 'md', motionProps }: EnterIconProps) => {
    const componentId = useId()
    const theme = useTheme()
    const { Icon, error, isLoaded } = useDynamicSvgImport(name as IconName)
    const iconScale = theme.iconScale[size]

    useEffect(() => {
        if (!isLoaded || typeof document === 'undefined') return

        const svg = document.getElementById(`${name}-${componentId}`) as SVGSVGElement | null

        if (!svg) return

        svg.setAttribute('viewBox', `0 0 ${svg.width.baseVal.value} ${svg.height.baseVal.value}`)
    }, [componentId, isLoaded, name, Icon])

    if (error) return null

    return (
        <motion.div
            css={css`
                ${getContainerStylesBySize(iconScale)}

                ${styles};
            `}
            {...OPACITY_TRANSITION}
            {...motionProps}
        >
            {Icon && <Icon id={`${name}-${componentId}`} />}
        </motion.div>
    )
}

/**
 *
 * @param iconName
 * @description This hook dynamically imports a svg icon by its file name.
 *
 * @see https://stackoverflow.com/questions/61339259/how-to-dynamically-import-svg-and-render-it-inline
 */
export const useDynamicSvgImport = (iconName: IconName) => {
    const importedIconRef = useRef<React.FC<React.SVGProps<SVGElement>>>()
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [error, setError] = useState<unknown>()

    const asyncImportSvgIcon = useCallback(async () => {
        setIsLoaded(false)

        try {
            // IMPORTANT: The path is not type safe. If the path changes, it needs to be updated manually.
            // This requires @svgr/webpack.
            importedIconRef.current = (await import(`!!@svgr/webpack!./svg/${iconName}.svg`)).default
        } catch (err) {
            setError(err)
        } finally {
            setIsLoaded(true)
        }
    }, [iconName])

    useEffect(() => {
        if (importedIconRef.current || isLoaded) return

        asyncImportSvgIcon()
    }, [asyncImportSvgIcon, isLoaded])

    return useMemo(() => ({ error, isLoaded, Icon: importedIconRef.current }), [error, isLoaded])
}
