import { AnimatePresence } from 'framer-motion'
import { css, SerializedStyles } from '@emotion/react'
import { ChildrenProps } from '@design-system/types/propTypes'
import { InputLabel } from '@design-system/components/forms/inputLabel'
import { ErrorMessage } from '@design-system/components/errorMessage'

export interface InputContainerProps extends ChildrenProps {
    label?: string
    containerStyles?: SerializedStyles
    labelStyles?: SerializedStyles
    labelErrorStyles?: SerializedStyles
    inputContainerStyles?: SerializedStyles
    error?: string | null
}

export const InputContainer = ({
    label,
    children,
    containerStyles,
    labelStyles,
    inputContainerStyles,
    error,
}: InputContainerProps) => (
    <div
        css={css`
            display: flex;
            flex-direction: column;

            ${containerStyles}
        `}
    >
        <div
            css={css`
                ${inputContainerStyles}
            `}
        >
            {label && (
                <InputLabel hasError={Boolean(error)} styles={labelStyles}>
                    {label}
                </InputLabel>
            )}

            {children}
        </div>

        <AnimatePresence initial={false}>{error && <ErrorMessage>{error}</ErrorMessage>}</AnimatePresence>
    </div>
)
