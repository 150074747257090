import { IconName } from '@design-system/enter-icons/enterIcon'
import {
    FolderSourceType,
    GetBuildings,
    MeasureSourceType,
    OpportunityStage,
    OpportunityType,
    SubsidyOpportunityStage,
} from '@design-system/types/buildings/buildingsTypes'

export const getLatestOppourtnity = (building: GetBuildings | undefined) => {
    const length = building?.serviceLineItems?.data?.length
    if (!length) return undefined

    const latestOpportunity = building?.serviceLineItems?.data?.[length - 1]
    return latestOpportunity
}

export const getLatestSubsidiesServiceLineItem = (building: GetBuildings | undefined) => {
    const subsidiesServiceLineItems = building?.serviceLineItems?.data?.filter(
        (lineItem) => lineItem.type === OpportunityType.SubsidiesService
    )
    const length = subsidiesServiceLineItems?.length
    if (!length || subsidiesServiceLineItems.length === 0) return undefined

    const latestLineItem = subsidiesServiceLineItems[length - 1]
    return latestLineItem
}

export const getLatestIsfpLineItem = (building: GetBuildings | undefined) => {
    const isfpLineItems = building?.serviceLineItems?.data?.filter(
        (lineItem) => lineItem.type === OpportunityType.RenovationSchedule
    )
    const length = isfpLineItems?.length
    if (!length || isfpLineItems.length === 0) return undefined

    const latestLineItem = isfpLineItems[length - 1]
    return latestLineItem
}

export const isAnySubsidiesPassedStage = (building: GetBuildings | undefined, targetStage: OpportunityStage) =>
    Boolean(
        building?.serviceLineItems?.data?.some((lineItem) => {
            if (lineItem.type === OpportunityType.RenovationSchedule) return false

            return getIsAtOrPassedOpportunityStage(lineItem.currentStage, targetStage)
        })
    )

const getIsAtOrPassedStage = <T>(currentStage: T | undefined, targetStage: T, stages: T[]) => {
    if (!currentStage || !targetStage) return false

    const allStages = stages.filter((k) => typeof k === 'string')
    // @ts-ignore
    const currentStageIndex = allStages.indexOf(currentStage)
    // @ts-ignore
    const targetStageIndex = allStages.indexOf(targetStage)

    return currentStageIndex >= targetStageIndex
}

export const getIsAtOrPassedOpportunityStage = (
    currentStage: OpportunityStage | undefined,
    targetStage: OpportunityStage
) => {
    return getIsAtOrPassedStage<OpportunityStage>(currentStage, targetStage, Object.values(OpportunityStage))
}

export const getIsAtOrPassedSubsidyOpportunityStage = (
    currentStage: SubsidyOpportunityStage | undefined,
    targetStage: SubsidyOpportunityStage
) => {
    return getIsAtOrPassedStage<SubsidyOpportunityStage>(
        currentStage,
        targetStage,
        Object.values(SubsidyOpportunityStage)
    )
}

// TODO: move in CAR-215 ticket
const measureIcons: Record<string, IconName> = {
    [MeasureSourceType.CeilingInsulation]: IconName.Roof,
    [MeasureSourceType.ExteriorWallInsulation]: IconName.Walls,
    [MeasureSourceType.SunProtection]: IconName.Sunscreens,
    [MeasureSourceType.SolarThermal]: IconName.SolarPanels,
    [MeasureSourceType.HeatingReplacement]: IconName.HeatPump,
    [MeasureSourceType.HeatingOptimization]: IconName.HeatPump,
    [MeasureSourceType.DoorReplacement]: IconName.Door,
    [MeasureSourceType.BasementCeilingInsulation]: IconName.BasementCeiling,
    [MeasureSourceType.TopFloorCeilingInsulation]: IconName.UpperFloor,
    [MeasureSourceType.AtticWindowReplacement]: IconName.RoofWindow,
    [MeasureSourceType.WindowReplacement]: IconName.Windows,
}

const folderIcons: Record<string, IconName> = {
    [FolderSourceType.RenovationSchedule]: IconName.LineChartUp03,
    [FolderSourceType.SubsidiesService]: IconName.CoinsStacked01,
}

export const getFolderIconName = (sourceType: string): IconName | undefined =>
    folderIcons[sourceType] || measureIcons[sourceType]
