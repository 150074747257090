import { Page } from '@utils/urls'
import { EnterAuthConfigContext } from '@design-system/utils/authentication'
import { AuthProcess } from '@design-system/types/authProcess'

/**
 * These auth processes are allowed to be accessed by logged in users
 * Be careful, just because an auth processes can be accessed, doesn't mean it should be in every case.
 */

// only for authenticated users
export type PublicLoggedInAuthProcesses =
    | AuthProcess.EMAIL_VERIFICATION
    | AuthProcess.SET_PASSWORD
    | AuthProcess.VALIDATE_PREVIOUS_PASSWORD

export const PUBLIC_ALLOWED_LOGGED_IN_AUTH_PROCESSES: PublicLoggedInAuthProcesses[] = [
    AuthProcess.EMAIL_VERIFICATION,

    // Only accessible when the user is logged in and has no password set yet.
    AuthProcess.SET_PASSWORD,

    AuthProcess.VALIDATE_PREVIOUS_PASSWORD,
]

/**
 * only for unauthenticated users
 */
export type PublicLoggedOutAuthProcesses =
    | AuthProcess.LOGIN_CHECK
    | AuthProcess.RESET_REQUEST
    | AuthProcess.RESET_PASSWORD

export const PUBLIC_LOGGED_OUT_AUTH_PROCESSES: PublicLoggedOutAuthProcesses[] = [
    AuthProcess.LOGIN_CHECK,
    AuthProcess.RESET_REQUEST,

    // This process needs to be public, as the reset email redirects to it with a verification token.
    AuthProcess.RESET_PASSWORD,
]

export type PublicAuthProcesses = PublicLoggedInAuthProcesses | PublicLoggedOutAuthProcesses

export const SONIC_AUTH_CONFIG: EnterAuthConfigContext = {
    authCookieName: process.env.NEXT_PUBLIC_AUTH_FIREBASE_COOKIE_NAME as string,
    googleClientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string,
    loginUrl: Page.LOGIN,
}

// Toggle features for the user app
export const FEATURES = {
    multiBuilding: false,
    newNavigation: true,
    multiOffer: true,
} as const
