import { motion } from 'framer-motion'
import { css, SerializedStyles } from '@emotion/react'
import { useTheme } from '@design-system/styles/theme'
import { StyleProps } from '@design-system/types/propTypes'

export interface SkeletonProps extends StyleProps, Record<'height' | 'width', string | number> {
    indexDelay?: number
    innerStyles?: SerializedStyles
}
export const Skeleton = ({ height, width, styles, innerStyles, indexDelay = 0 }: SkeletonProps) => {
    const theme = useTheme()

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                opacity: {
                    duration: 0.3,
                    delay: 0.1 + indexDelay * 0.08,
                    ease: 'linear',
                },
            }}
            css={css`
                border-radius: 8px;
                height: ${typeof height === 'number' ? `${height}px` : height};
                width: ${typeof width === 'number' ? `${width}px` : width};
                ${styles};
            `}
        >
            <motion.div
                animate={{ opacity: [0.7, 0.9, 0.7] }}
                transition={{ repeat: Infinity, ease: 'linear', duration: 1 }}
                css={css`
                    background: ${theme.colors.background.standard};
                    border-radius: 8px;
                    height: ${typeof height === 'number' ? `${height}px` : height};
                    width: 100%;

                    ${innerStyles};
                `}
            />
        </motion.div>
    )
}
