import { ChildrenProps } from '@design-system/types/propTypes'
import { createContext, useContext } from 'react'
import { useTheme } from '@design-system/styles/theme'
import { useWindowBreakpoint } from '@design-system/utils/hooks'

type ScreenSizeProviderProps = ChildrenProps

interface DesktopContext {
    isDesktop: boolean
    isMobile: boolean
    desktopMediaQuery: string
}

export const DesktopContext = createContext<DesktopContext>({
    isDesktop: false,
    isMobile: false,
    desktopMediaQuery: '',
})

export const ScreenSizeProvider = ({ children }: ScreenSizeProviderProps) => {
    const { isDesktop, isMobile, desktopMediaQuery } = useBaseDesktop()

    if (isDesktop === undefined || isMobile === undefined) return null

    return (
        <DesktopContext.Provider
            value={{
                isDesktop,
                isMobile,
                desktopMediaQuery,
            }}
        >
            {children}
        </DesktopContext.Provider>
    )
}

export const useBaseDesktop = () => {
    const theme = useTheme()

    const { matches: isDesktop, mediaQuery: desktopMediaQuery } = useWindowBreakpoint({
        breakpoint: theme.breakpoints.md,
        direction: 'up',
    })

    const { matches: isMobile } = useWindowBreakpoint({
        breakpoint: theme.breakpoints.xs,
        direction: 'down',
    })

    return { isDesktop, isMobile, desktopMediaQuery }
}

export const useDesktop = () => useContext(DesktopContext)
