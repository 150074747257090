import { AnyObject } from '@design-system/types/advanced'
import DOMPurify from 'dompurify'
import { KeyboardEvent } from 'react'
import { IllustrationName } from '@design-system/enter-icons/enterIllustration'

export const objectKeys = <T extends AnyObject>(object: T): (keyof T)[] => Object.keys(object) as (keyof T)[]

export const objectEntries = <T extends AnyObject>(object: T): [keyof T, T[keyof T]][] =>
    Object.entries(object) as [keyof T, T[keyof T]][]

export const numberWithBoundaries = (number: number, min: number, max: number) =>
    number > max ? max : number < min ? min : number

export type Color = [number, [number, number, number]]

export const pickRGBFromLinearGradient = (totalWidth: number, currentPoint: number, gradient: Color[]) => {
    const currentPointPercentage = (currentPoint / totalWidth) * 100
    const sortedGradientPercentageBreakpoints = gradient.map((color) => color[0]).sort()

    const intersectingPercentageBreakpoints = sortedGradientPercentageBreakpoints.reduce<[number, number]>(
        (acc, breakpoint) => {
            if (breakpoint < currentPointPercentage) return [breakpoint, acc[1]]
            if (breakpoint >= currentPointPercentage) return [acc[0], breakpoint]

            return acc
        },
        [0, 0]
    )

    const firstColor = gradient.find(([breakpoint]) => breakpoint === intersectingPercentageBreakpoints[0])?.[1]

    const secondColor = gradient.find(([breakpoint]) => breakpoint === intersectingPercentageBreakpoints[1])?.[1]

    if (!firstColor || !secondColor) return

    const firstColorX = (intersectingPercentageBreakpoints[0] / 100) * totalWidth
    const secondColorX = (intersectingPercentageBreakpoints[1] / 100) * totalWidth - firstColorX

    const currentPointX = totalWidth * (currentPointPercentage / 100) - firstColorX
    const ratio = currentPointX / secondColorX

    const w1 = ((ratio * 2 - 1) / 1 + 1) / 2
    const w2 = 1 - w1

    return `rgb(${Math.round(secondColor[0] * w1 + firstColor[0] * w2)},${Math.round(
        secondColor[1] * w1 + firstColor[1] * w2
    )},${Math.round(secondColor[2] * w1 + firstColor[2] * w2)})`
}

export const round = (value: number, decimals: number) => Math.round(value * 10 ** decimals) / 10 ** decimals

export const parseStringToReactHTML = (dirtyString: string) => DOMPurify.sanitize(dirtyString, { ADD_ATTR: ['target'] })

/**
 * Helper to run immediately-invoked-function-expressions (IIFE) inline and clean.
 */
export const run =
    // eslint-disable-next-line
    <T extends () => any, TReturn extends ReturnType<T>>(callback: T): TReturn => callback()

// eslint-disable-next-line
export const noOp = () => {}

export const omit = <T, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> => {
    const result = { ...obj }
    keys.forEach((key) => delete result[key])
    return result
}

export const partition = <T>(arr: T[], predicate: (item: T) => boolean): [T[], T[]] =>
    arr.reduce(
        (acc, item) => {
            if (predicate(item)) {
                acc[0].push(item)
            } else {
                acc[1].push(item)
            }

            return acc
        },
        [[] as T[], [] as T[]]
    )

export const range = (end: number, start = 0, step = 1) => {
    const range = []

    for (let i = start; i < end; i += step) {
        range.push(i)
    }

    return range
}

export const truncate = (str: string, length: number) => {
    if (str.length > length) {
        return `${str.substring(0, length)}...`
    }

    return str
}

export const handleEnterPress = (event: KeyboardEvent<HTMLElement>, callback: () => void) => {
    if (event.key === 'Enter') {
        callback()
    }
}

export const pick = <T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> => {
    const result = {} as Pick<T, K>
    keys.forEach((key) => {
        result[key] = obj[key]
    })
    return result
}

export const debounce = (func: (...args: any[]) => void, wait: number) => {
    let timeout: ReturnType<typeof setTimeout>
    return (...args: Parameters<typeof func>) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => func(...args), wait)
    }
}

/**
 * @param rawPath The raw path to parse
 * @param obj An enum object with the possible path values.
 *
 * Function that parses an unprotected router path to a specific type,
 */
export const parseUnprotectedRouterPath = <T extends AnyObject, K extends keyof T>(
    rawPath: unknown,
    obj: T
): T[K] | undefined => {
    if (typeof rawPath !== 'string') return undefined

    return Object.values(obj).includes(rawPath) ? (rawPath as T[K]) : undefined
}

export function parseRouterParam(param: unknown, paths: 'multiple'): string[] | undefined
export function parseRouterParam(param: unknown, paths?: 'single'): string | undefined
export function parseRouterParam(param: unknown, paths: 'single' | 'multiple' = 'single') {
    if (typeof param === 'string') {
        return paths === 'single' ? param : undefined
    }

    if (Array.isArray(param) && param.every((item) => typeof item === 'string')) {
        return paths === 'multiple' ? (param as string[]) : undefined
    }

    return undefined
}

/**
 * Helper so we can have a const object as enum, and a type as its value.
 *
 * ```typescript
 * const StatusEnum = {
 *   Active: 'ACTIVE',
 *   Inactive: 'INACTIVE',
 * } as const;
 *
 * type StatusType = Values<typeof StatusEnum>; // 'ACTIVE' | 'INACTIVE'
 * ```
 */
export type Values<T> = T[keyof T]

export const isNestedArray = (arr: unknown) => {
    return Array.isArray(arr) && Array.isArray(arr[0])
}

export const mapRoofShapeToIllustration = (roofShape: string | undefined) => {
    switch (roofShape) {
        case 'flat':
            return IllustrationName.FlatRoofHouse
        case 'gable':
            return IllustrationName.GableRoofHouse
        case 'hip':
            return IllustrationName.HipRoofHouse
        case 'shed':
            return IllustrationName.ShedRoofHouse
        default:
            return IllustrationName.HipRoofHouse
    }
}

export const getFloatFormatted = (value: number, decimalPlaces = 2) => {
    let formattedValue = value.toFixed(decimalPlaces)
    formattedValue = formattedValue.replace('.', ',')
    return formattedValue
}
