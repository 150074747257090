import { css } from '@emotion/react'
import { useTheme } from '@design-system/styles/theme'
import { StyleProps, ChildrenProps } from '@design-system/types/propTypes'

type TextProps = ChildrenProps & StyleProps

export const Text = ({ children, styles }: TextProps) => {
    const theme = useTheme()

    return (
        <p
            css={css`
                font-size: ${theme.fontSize.text || theme.fontSize.bodyDefault};
                font-family: ${theme.fontFamily.bodyDefault};

                line-height: ${theme.lineHeight.text || theme.lineHeight.bodyDefault};
                color: ${theme.colors.text.primary.onLight};

                ${styles}
            `}
        >
            {children}
        </p>
    )
}
