import { useState, useEffect } from 'react'
import { cookieName, getFirebaseApp } from '@design-system/utils/firebaseAuthentication'
import { getAuth, onAuthStateChanged, onIdTokenChanged } from '@firebase/auth'
import { datadogRum } from '@datadog/browser-rum'
import axios from 'axios'

export type LoggedStateType = {
    isLoggedIn: boolean
    isLoading: boolean
    idToken: string
}

export const useAuthentication = () => {
    const auth = getAuth(getFirebaseApp())
    const [loggedState, setLoggedState] = useState({
        isLoggedIn: false,
        isLoading: true,
        idToken: '',
    })

    const setLoggedStateWrapper = (newState: LoggedStateType) => {
        if (newState.idToken?.length > 0) {
            localStorage.setItem(cookieName, newState.idToken)
        }

        setLoggedState({ ...newState })
    }

    const refreshToken = async (forceRefresh = true) => {
        const newAuth = getAuth(getFirebaseApp())
        if (newAuth.currentUser) {
            try {
                const token = await newAuth.currentUser.getIdToken(forceRefresh)
                localStorage.setItem(cookieName, token)

                setLoggedStateWrapper({
                    isLoggedIn: true,
                    isLoading: false,
                    idToken: token,
                })

                return token
            } catch (error) {
                datadogRum.addError(error)
                return null
            }
        }
        return null
    }

    // Auth state listener
    useEffect(() => {
        const newAuth = getAuth(getFirebaseApp())
        const unsubscribe = onAuthStateChanged(newAuth, async (user) => {
            if (user) {
                const token = await refreshToken(false)
                localStorage.setItem(cookieName, token as string)
                if (!token) return
            } else {
                setLoggedStateWrapper({
                    isLoggedIn: false,
                    isLoading: false,
                    idToken: '',
                })
            }
        })

        return () => unsubscribe()
    }, [])

    // Token change listener
    useEffect(() => {
        const newAuth = getAuth(getFirebaseApp())
        const unsubscribe = onIdTokenChanged(newAuth, async (user) => {
            try {
                if (user) {
                    const token = await refreshToken(false)
                    localStorage.setItem(cookieName, token as string)
                    if (!token) return
                } else {
                    setLoggedStateWrapper({
                        isLoggedIn: false,
                        isLoading: false,
                        idToken: '',
                    })
                }
            } catch (error) {
                datadogRum.addError(error)
                setLoggedStateWrapper({
                    isLoggedIn: false,
                    isLoading: false,
                    idToken: '',
                })
            }
        })

        return () => unsubscribe()
    }, [])

    // Axios interceptors
    useEffect(() => {
        // Request interceptor
        const requestInterceptor = axios.interceptors.request.use(
            async (config) => {
                if (getAuth(getFirebaseApp()).currentUser) {
                    try {
                        const token = await refreshToken(false)
                        if (token) {
                            localStorage.setItem(cookieName, token as string)
                            config.headers.Authorization = `Bearer ${token}`
                            config.headers['x-id-token'] = token
                            config.headers['Referer'] = 'customer-portal'
                        }
                    } catch (error) {
                        datadogRum.addError(error)
                    }
                }
                return config
            },
            (error) => {
                return Promise.reject(error)
            }
        )

        // Response interceptor
        const responseInterceptor = axios.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config

                // Handle 401 errors and token refresh
                if (error.response?.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true

                    try {
                        const token = await refreshToken(true)
                        if (token) {
                            localStorage.setItem(cookieName, token as string)
                            originalRequest.headers.Authorization = `Bearer ${token}`
                            originalRequest.headers['x-id-token'] = token
                            originalRequest.headers['Referer'] = 'customer-portal'
                            return axios(originalRequest)
                        }
                    } catch (refreshError) {
                        datadogRum.addError(refreshError)
                        setLoggedStateWrapper({
                            isLoggedIn: false,
                            isLoading: false,
                            idToken: '',
                        })
                    }
                }

                return Promise.reject(error)
            }
        )

        // Cleanup
        return () => {
            axios.interceptors.request.eject(requestInterceptor)
            axios.interceptors.response.eject(responseInterceptor)
        }
    }, [])

    return {
        ...loggedState,
        setLoggedState: setLoggedStateWrapper,
        auth,
        refreshToken,
    }
}
