import { ReactNode, useCallback, useEffect, useState } from 'react'
import {
    isUserLoggedInWithUserUUID,
    validateAndSignInWithCustomToken,
} from '@design-system/utils/firebaseAuthentication'
import { AUTH_USER_UUID, USER_CUSTOM_TOKEN } from '@design-system/utils/authentication'

type FirebaseCustomAuthenticationProps = {
    children: ReactNode
    userCustomToken: string
    authUserUUID: string
}

export const FirebaseCustomAuthentication = ({
    children,
    userCustomToken,
    authUserUUID,
}: FirebaseCustomAuthenticationProps) => {
    const [error, setError] = useState<unknown>(undefined)

    const redirectToSamePathWithoutQueryParams = () => {
        const url = new URL(window.location.href)
        url.searchParams.delete(USER_CUSTOM_TOKEN)
        url.searchParams.delete(AUTH_USER_UUID)

        window.location.href = url.toString()
    }

    const signInCallback = useCallback((token: string, userUUID: string) => {
        if (isUserLoggedInWithUserUUID(userUUID)) {
            redirectToSamePathWithoutQueryParams()
            return
        }

        validateAndSignInWithCustomToken(token, '', userUUID)
            .then(() => {
                redirectToSamePathWithoutQueryParams()
            })
            .catch((e) => {
                setError(e)
            })
    }, [])

    useEffect(() => {
        if (!userCustomToken) return

        signInCallback(userCustomToken, authUserUUID)
    }, [userCustomToken, authUserUUID, signInCallback])

    if (error) return `Error signing in ${error}`

    if (userCustomToken) return 'Signing in...'

    return children
}
