import { css, SerializedStyles } from '@emotion/react'
import { useTheme } from '@design-system/styles/theme'
import { StyleProps, ChildrenProps } from '@design-system/types/propTypes'

export interface InputLabelProps extends ChildrenProps, StyleProps {
    hasError?: boolean
    errorStyles?: SerializedStyles
}

export const InputLabel = ({ children, styles, errorStyles, hasError }: InputLabelProps) => {
    const theme = useTheme()

    return (
        <label
            css={css`
                transition: color 0.3s ease;
                color: ${theme.colors.text.secondary.onLight};
                font-size: ${theme.fontSize.inputLabel || theme.fontSize.bodyDefault};
                font-weight: ${theme.fontWeight.inputLabel || theme.fontWeight.bodyDefault};
                line-height: ${theme.lineHeight.inputLabel || theme.lineHeight.bodyDefault};

                ${hasError && errorStyles}

                ${styles}
            `}
        >
            {children}
        </label>
    )
}
