import { IconName } from '@design-system/enter-icons/enterIcon'
import { OpportunityStage } from '@design-system/types/buildings/buildingsTypes'

export enum OwnerType {
    ADMIN = 'admin',
    SCOUT = 'scout',
    USER = 'user',
}

export enum DocumentResponseSection {
    admin = 'upload',
    user = 'view',
}

export enum DocumentUserResponseSection {
    admin = DocumentResponseSection.user,
    user = DocumentResponseSection.admin,
}

export enum SourceType {
    USER = 'user',
    INTERNAL = 'internal',
    SCOUT = 'scout',
}

export type Folder = {
    uuid: string
    name: string
    creation_date: string
    salesforce_id: string
    source_uuid: string
    source_type: string
    path: string
    tabs: Folder[]
    IconName: IconName
    current_stage?: OpportunityStage
}

export type Folders<T> = {
    folder: T
    children?: Folders<T>[]
}

export type GetFoldersResponse = {
    folder: Folder
    children?: Folders<Folder>[]
}

export type TagType = {
    action_type: string
    mandatory_type: 'optional' | 'mandatory'
    tag_uuid: string
    service_type: string
    service_stage: string
    user_role: OwnerType
    tag_name: string
    tag_key: string
    uploaded: boolean
}

export type ActionHistoryType = {
    uuid: string
    action_type: ActionHistoryActionType
    action_comment: string
    created_at: string
    document_uuid: string
    user_uuid: string
}

export type Document = {
    uuid: string
    name: string
    attributes: {
        size: string
        bucket: string
    }
    action_history?: ActionHistoryType
    created_at: string
    updated_at: string
    creator_uuid: string
    thumbnail_url: string
    tags: TagType[]
}

export type GetDocumentsResponse = {
    salesforce_id: string | null
    stage: string | null
    [DocumentResponseSection.user]: Document[]
    [DocumentResponseSection.admin]: Document[]
}

export type GetFolderTagsResponse = {
    parent_source_type: string
    parent_source_uuid: string
    tags: TagType[]
}

export type RequestFileUploadPayload = {
    fileNames: File[]
    documentUploadRequest: {
        tags: string[]
    }
}

export type RequestTagsModifyPayload = {
    document: string
    add?: string[]
    remove?: string[]
}

export type RequestFileUploadResponse = {
    buildinguuid: string
    uploadResults: string[]
}

export type RequestActionHistoryPayload = {
    actionType: ActionHistoryActionType
    actionComment: string
}

export type RequestFileDeletePayload = {
    folderUuid: string
}

export type ActionHistoryActionType =
    | DocumentSpaceFileOperation.APPROVED
    | DocumentSpaceFileOperation.PENDING
    | DocumentSpaceFileOperation.REJECTED

export type RequestFormDataUploadPayload = FormData

export type RequestFileDownloadResponse = {
    signed_url: string
}

export type RequestViewAllDocumentsResponse = {
    signed_urls: string[]
}

export type RequestAllDocumentsPayload = {
    folderUuid: string
}

export type RejectionReason = {
    label: string
    value: string
    title: string
    content: string
}

export type GetAllRejectionReasonsResponse = {
    rejection_reasons: RejectionReason[]
}

export enum DocumentSpaceFileOperation {
    DELETE = 'delete',
    DOWNLOAD = 'download',
    DELETE_ALL_TAGS = 'delete-all-tags',
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    VIEW = 'view',
}
