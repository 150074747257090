import { createContext, useContext } from 'react'
import { ChildrenProps } from '@design-system/types/propTypes'
import { AuthProcess } from '@design-system/types/authProcess'

export const encryptPassword = async (message: string): Promise<string> => {
    const JSEncrypt = (await import('jsencrypt')).default
    const jsencrypt = new JSEncrypt()
    if (process.env.NEXT_PUBLIC_IS_KUBERNETES === 'true') {
        jsencrypt.setPublicKey(atob(process.env.NEXT_PUBLIC_BAUPAL_RSA_PUBLIC_KEY_BASE64 as string))
    } else {
        jsencrypt.setPublicKey(process.env.NEXT_PUBLIC_BAUPAL_RSA_PUBLIC_KEY as string)
    }
    return jsencrypt.encrypt(message) || message
}

export interface EnterAuthConfigContext {
    loginUrl: string
    googleClientId: string
    authCookieName: string
}

const EnterAuthContext = createContext<EnterAuthConfigContext>({
    loginUrl: '',
    googleClientId: '',
    authCookieName: '',
})

interface EnterAuthProviderProps extends ChildrenProps {
    config: EnterAuthConfigContext
}

export const EnterAuthConfigProvider = (props: EnterAuthProviderProps) => (
    <EnterAuthContext.Provider value={props.config}>{props.children}</EnterAuthContext.Provider>
)

export const useEnterAuthConfig = () => useContext(EnterAuthContext)

// --- FEATURE CONTEXT ---

export type FeatureFlagType = Record<string, boolean>

export const featureContext = (<T extends FeatureFlagType>() => createContext<T>({ ...({} as T) }))()

export const useFeatureContext = <T extends FeatureFlagType>() =>
    useContext<T>(featureContext as unknown as React.Context<T>)

export const USER_CUSTOM_TOKEN = 'impersonation-custom-admin-auth-token' as const
export const AUTH_USER_UUID = 'customer-uuid' as const

export const parseUnprotectedAuthProcess = (rawAuthProcess: unknown): AuthProcess | undefined => {
    if (typeof rawAuthProcess !== 'string') return undefined

    return Object.values(AuthProcess).includes(rawAuthProcess as AuthProcess)
        ? (rawAuthProcess as AuthProcess)
        : undefined
}

export const getUnexpiredToken = (token: string, expiry: string) => {
    if (Date.now() < parseInt(expiry) && token) {
        return token
    }
    return ''
}
