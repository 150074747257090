import { SkeletonSavingsBreakdown } from '@design-system/components/widgets/savingsPotentialRoboAdviceWidget/skeletons/skeletonSavingsBreakdown'
import { Card } from '@design-system/components/card'

export const LoadingIndicator = () => {
    return (
        <Card>
            <SkeletonSavingsBreakdown />
        </Card>
    )
}
