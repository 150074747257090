import { Skeleton } from '@design-system/components/skeleton'
import { css } from '@emotion/react'
import { useTheme } from '@design-system/styles/theme'
import { useDesktop } from '../../../../context/screenSizeContext'

interface SkeletonSavingsBreakdownProps {
    hideText?: boolean
}

export const SkeletonSavingsBreakdown = ({ hideText = false }: SkeletonSavingsBreakdownProps) => {
    const theme = useTheme()
    const { isDesktop, desktopMediaQuery } = useDesktop()

    return (
        <>
            <div
                css={css`
                    background: ${theme.colors.background.light};
                    padding: ${theme.spacing(2)};
                    border-radius: 18px;

                    ${desktopMediaQuery} {
                        background: unset;
                        padding: unset;
                        border-radius: unset;
                    }
                `}
            >
                {!hideText && (
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            margin-bottom: ${theme.spacing(5)};
                            row-gap: ${theme.spacing(1)};
                        `}
                    >
                        <Skeleton height={20} width={`100%`} />
                        <Skeleton height={20} width={`100%`} />
                        <Skeleton height={20} width={`100%`} />
                    </div>
                )}

                <SkeletonBreakdownItem />
                <SkeletonBreakdownItem />
                <SkeletonBreakdownItem />
            </div>

            {!isDesktop && (
                <Skeleton
                    height={30}
                    width={`100%`}
                    styles={css`
                        margin-bottom: ${theme.spacing(1)};
                    `}
                />
            )}
        </>
    )
}

const SkeletonBreakdownItem = () => {
    const theme = useTheme()

    return (
        <div
            css={css`
                margin-bottom: ${theme.spacing(2)};
            `}
        >
            <div
                css={css`
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: ${theme.spacing(1)};
                `}
            >
                <Skeleton height={20} width={`40%`} />
                <Skeleton height={20} width={`40%`} />
            </div>

            <Skeleton height={12} width={`100%`} />
        </div>
    )
}
