import { css } from '@emotion/react'
import { Button, ButtonProps } from '@design-system/components/button/button'
import { Heading } from '@design-system/components/heading'
import { Modal, ModalProps } from '@design-system/components/modal'
import { Text } from '@design-system/components/text'
import { useTheme } from '@design-system/styles/theme'
import { omit } from '@design-system/utils/helpers'

export type ConfirmationModalProps = ModalProps & {
    title: string
    description: string
    confirmButton: Omit<ButtonProps, 'children' | 'styleType'> & { text: string }
    cancelButton: Omit<ButtonProps, 'children' | 'styleType'> & { text: string }
    isPending?: boolean
}

export const ConfirmationModal = ({
    title,
    description,
    cancelButton,
    confirmButton,
    isPending = false,
    ...modalProps
}: ConfirmationModalProps) => {
    const theme = useTheme()

    return (
        <Modal
            modalStyles={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: ${theme.spacing(4)};
                width: 315px;
            `}
            {...modalProps}
        >
            <Heading
                level={3}
                styles={css`
                    margin-bottom: ${theme.spacing(1)};
                `}
            >
                {title}
            </Heading>

            <Text
                styles={css`
                    text-align: center;
                    margin-bottom: ${theme.spacing(3.5)};
                `}
            >
                {description}
            </Text>

            <Button
                styleType="primary"
                buttonStyles={css`
                    width: 100%;
                    margin-bottom: ${theme.spacing(3.5)};
                `}
                {...omit(confirmButton, 'text')}
                isLoading={isPending}
            >
                {confirmButton.text}
            </Button>

            <div>
                <Button styleType="inline-unstyled" {...omit(cancelButton, 'text')} isDisabled={isPending}>
                    {cancelButton.text}
                </Button>
            </div>
        </Modal>
    )
}
