import { SerializedStyles, css } from '@emotion/react'
import { EnterTheme } from '@design-system/styles/theme'

export const createGlobalStyle = (theme: EnterTheme, customGlobalStyles: SerializedStyles) => css`
    :root {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-synthesis: none;
        text-rendering: optimizelegibility;
        text-size-adjust: 100%;
    }

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    /* Remove all ui animations and transitions for people that prefer not to see them */
    @media (prefers-reduced-motion: reduce) {
        * {
            animation-duration: 0.01ms !important;
            animation-iteration-count: 1 !important;
            transition-duration: 0.01ms !important;
            scroll-behavior: auto !important;
        }
    }

    html {
        font-family: ${theme.fontFamily.bodyDefault};
        font-size: ${theme.fontSize.bodyDefault};
        line-height: ${theme.lineHeight.bodyDefault};
        color: ${theme.colors.text.primary.onLight};
    }

    html,
    body,
    p {
        margin: 0;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    address {
        font-style: normal;
    }

    h1 {
        font-size: ${theme.fontSize.h1};
        line-height: ${theme.lineHeight.h1 || theme.lineHeight.headingDefault};
        font-weight: ${theme.fontWeight.h1 || theme.fontWeight.headingDefault};
        font-family: ${theme.fontFamily.bodyDefault};
        color: ${theme.colors.text.primary.onLight};
        margin: 0;
    }

    h2 {
        font-size: ${theme.fontSize.h2};
        line-height: ${theme.lineHeight.h2 || theme.lineHeight.headingDefault};
        font-weight: ${theme.fontWeight.h2 || theme.fontWeight.headingDefault};
        font-family: ${theme.fontFamily.bodyDefault};
        color: ${theme.colors.text.primary.onLight};
        margin: 0;
    }

    h3 {
        font-size: ${theme.fontSize.h3};
        line-height: ${theme.lineHeight.h3 || theme.lineHeight.headingDefault};
        font-weight: ${theme.fontWeight.h3 || theme.fontWeight.headingDefault};
        font-family: ${theme.fontFamily.bodyDefault};
        color: ${theme.colors.text.primary.onLight};
        margin: 0;
    }

    h4 {
        font-size: ${theme.fontSize.h4};
        line-height: ${theme.lineHeight.h4 || theme.lineHeight.headingDefault};
        font-weight: ${theme.fontWeight.h4 || theme.fontWeight.headingDefault};
        font-family: ${theme.fontFamily.bodyDefault};
        color: ${theme.colors.text.primary.onLight};
        margin: 0;
    }

    h5 {
        font-size: ${theme.fontSize.h5};
        line-height: ${theme.lineHeight.h5 || theme.lineHeight.headingDefault};
        font-weight: ${theme.fontWeight.h5 || theme.fontWeight.headingDefault};
        color: ${theme.colors.text.primary.onLight};
        margin: 0;
    }

    h6 {
        font-size: ${theme.fontSize.h6};
        line-height: ${theme.lineHeight.h6 || theme.lineHeight.headingDefault};
        font-weight: ${theme.fontWeight.h6 || theme.fontWeight.headingDefault};
        font-family: ${theme.fontFamily.bodyDefault};
        color: ${theme.colors.text.primary.onLight};
        margin: 0;
    }

    ${customGlobalStyles}
`
